@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
    background: #0a0a0a;
    color: #eee;
}

:root {
    color-scheme: dark;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background: rgb(0, 108, 255);
    border-radius: 10px;
}

@media screen and (max-width: 768px) {
    body {
        font-size: 12px;
    }
}